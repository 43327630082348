

export const projects = [
    {
        "id": 1,
        "name": "Veneficus",
        "url": "https://veneficus.io/en/",
        "img": "./assets/projects/veneficus.jpg",
        "description": "Worked with team on this project for Veneficus using HTML, CSS, Webpack."
    },
    {
        "id": 2,
        "name": "Aan de bak garantie",
        "url": "https://aandebakgarantie.nl/",
        "img": "./assets/projects/aandebak.png",
        "description": "Interactive website for students to pick an education field. React.js , CSS."
    },
    {
        "id": 3,
        "name": "STC grenzeloos",
        "url": "https://www.stc-grenzeloos.nl/",
        "img": "./assets/projects/stc.png",
        "description": "Promotional website for STC. React.js, CSS."
    },
    {
        "id": 4,
        "name": "Voorgoed agency",
        "url": "http://voorgoedagency.nl/",
        "img": "./assets/projects/voorgoed.jpg",
        "description": "Single page application for Impact. Hugo framework, CSS / SASS."
    },

]