import React from 'react'

export default function Footer() {
    return (
        <>
            <footer>
                &copy;Sonny Lo
            </footer>
        </>
    )
}
