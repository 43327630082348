import React from 'react'


export default function Hero() {
    return (
        <div className="container" id="aboutme">
            <h3>Sonny Lo</h3>
            <hr />
            <p>Full-stack web developer who loves coding.</p>
            <p>I love to read and write codes and see them do awesome stuff all the while listening to kickass music to get me motivated. </p>
            {/* <svg width="640" height="480" xmlns="http://www.w3.org/2000/svg" xmlnssvg="http://www.w3.org/2000/svg">
                <g className="layer">
                    <title>Layer 1</title>
                    <path d="m423.38222,350.06642c0,3.92187 -3.17739,7.09926 -7.09927,7.09926l-45.46181,0c-3.92187,0 -7.09927,-3.17738 -7.09927,-7.09926l0,-96.58521c0,-3.92156 3.17739,-7.09926 7.09927,-7.09926l45.46181,0c3.92188,0 7.09927,3.1777 7.09927,7.09926l0,96.58521zm-168.3122,0c0,3.92187 -3.17801,7.09926 -7.09927,7.09926l-45.46243,0c-3.92126,0 -7.09926,-3.17738 -7.09926,-7.09926l0,-96.58521c0,-3.92156 3.178,-7.09926 7.09926,-7.09926l45.46243,0c3.92126,0 7.09927,3.1777 7.09927,7.09926l0,96.58521zm54.92403,-261.90074c-82.26544,0 -149.04591,66.17655 -150.0715,148.19095l26.04456,0c0.84452,-67.78581 56.04044,-122.47875 124.02694,-122.47875c67.98649,0 123.18244,54.69294 124.01709,122.47875l26.04395,0c-1.01513,-82.0144 -67.81525,-148.19095 -150.06105,-148.19095l0.00001,0zm-149.86037,223.31693l25.57197,0l0,-75.27696l-25.57197,0l0,75.27696zm273.66636,-75.28709l0,75.27728l26.51651,0l0,-75.27728l-26.51651,0z" fill="#000000" id="headphone" stroke="#ffff00" strokeDasharray="null" />
                    <path d="m417.80054,106.21274c-4.04966,-0.73236 -5.4461,-5.84847 -3.03171,-8.91358c3.2042,-4.7404 10.0583,-7.93603 15.42857,-4.94495c0.10702,-16.74777 0.04527,-33.49646 0.06163,-50.24464c13.39014,-2.64896 26.81056,-5.15003 40.20538,-7.77706c-0.02901,18.709 -0.05802,37.418 -0.08702,56.127c-2.09856,5.65872 -8.87946,9.5827 -14.78391,7.82802c-3.51521,-1.05225 -4.35794,-5.5896 -2.40052,-8.3697c2.68049,-4.14705 7.66955,-6.99436 12.68516,-6.21757c1.43132,-0.14479 3.53904,2.17249 2.93407,-0.44895c-0.04969,-14.798 0.12358,-29.59995 -0.12784,-44.39484c-2.18527,-0.81129 -6.07157,1.05532 -8.86955,1.20634c-9.35659,1.821 -18.73319,3.54549 -28.06829,5.47293c0.28167,16.39671 0.15033,32.80345 0.10839,49.20362c0.49562,3.74371 -1.56779,7.27023 -4.70522,9.22174c-2.70737,1.96935 -6.05231,2.67928 -9.34914,2.25164l0,0z" fill="none" id="music_note_3" stroke="#3f7f00" />
                    <path d="m519.91729,176.9278c-5.23081,-0.94596 -7.03455,-7.55427 -3.91596,-11.51337c4.13876,-6.12301 12.99197,-10.2507 19.92857,-6.38723c0.13823,-21.63253 0.05848,-43.26625 0.07961,-64.89932c17.2956,-3.42156 34.6303,-6.65212 51.93195,-10.04536c-0.03748,24.16578 -0.07495,48.33157 -0.11241,72.49736c-2.71064,7.30918 -11.4693,12.37765 -19.09587,10.11119c-4.54049,-1.35915 -5.62901,-7.2199 -3.10068,-10.81086c3.4623,-5.3566 9.9065,-9.03437 16.385,-8.03102c1.84879,-0.18702 4.57126,2.80614 3.78984,-0.57989c-0.06418,-19.11409 0.15961,-38.23327 -0.16514,-57.34334c-2.82263,-1.04792 -7.84243,1.36313 -11.45649,1.5582c-12.0856,2.35211 -24.19703,4.57959 -36.25487,7.06919c0.36382,21.17908 0.19417,42.37112 0.14001,63.55468c0.64016,4.83562 -2.02507,9.3907 -6.07759,11.91141c-3.49702,2.54375 -7.81756,3.46073 -12.07597,2.90837l0,-0.00001z" fill="none" id="music_note_4" stroke="#ffaaff" />
                    <path d="m117.77931,130.11942c-4.49962,-0.81372 -6.05122,-6.49829 -3.36856,-9.90397c3.56022,-5.26711 11.17589,-8.81781 17.14285,-5.49439c0.11891,-18.60864 0.05031,-37.21829 0.06848,-55.82738c14.87794,-2.94328 29.78951,-5.72225 44.67265,-8.64117c-0.03224,20.78777 -0.06447,41.57555 -0.0967,62.36332c-2.33173,6.28747 -9.86606,10.64745 -16.42655,8.69781c-3.9058,-1.16917 -4.84216,-6.21067 -2.66726,-9.29967c2.97833,-4.60783 8.52173,-7.7715 14.09463,-6.90841c1.59035,-0.16088 3.93227,2.41389 3.26008,-0.49883c-0.05521,-16.44223 0.1373,-32.88883 -0.14205,-49.3276c-2.42808,-0.90144 -6.74618,1.17258 -9.85506,1.34038c-10.39621,2.02333 -20.81464,3.93943 -31.18698,6.08103c0.31296,18.21856 0.16703,36.44828 0.12043,54.67069c0.55068,4.15968 -1.74199,8.07803 -5.22802,10.24638c-3.00819,2.18817 -6.72479,2.97697 -10.38793,2.50182l-0.00001,-0.00001z" fill="none" id="music_note_2" stroke="#7f0000" />
                    <path d="m27.58601,209.36692c-4.49962,-0.81372 -6.05122,-6.49829 -3.36856,-9.90397c3.56022,-5.26711 11.17589,-8.81781 17.14285,-5.49439c0.11891,-18.60864 0.05031,-37.21829 0.06848,-55.82738c14.87794,-2.94328 29.78951,-5.72225 44.67265,-8.64117c-0.03224,20.78777 -0.06447,41.57555 -0.0967,62.36332c-2.33173,6.28747 -9.86606,10.64745 -16.42655,8.69781c-3.9058,-1.16917 -4.84216,-6.21067 -2.66726,-9.29967c2.97833,-4.60783 8.52173,-7.7715 14.09463,-6.90841c1.59035,-0.16088 3.93227,2.41389 3.26008,-0.49883c-0.05521,-16.44223 0.1373,-32.88883 -0.14205,-49.3276c-2.42808,-0.90144 -6.74618,1.17258 -9.85506,1.34038c-10.39621,2.02333 -20.81464,3.93943 -31.18698,6.08103c0.31296,18.21856 0.16703,36.44828 0.12043,54.67069c0.55068,4.15968 -1.74199,8.07803 -5.22802,10.24638c-3.00819,2.18817 -6.72479,2.97697 -10.38793,2.50182l-0.00001,-0.00001z" fill="none" id="music_note_1" stroke="#0000ff" />
                </g>
            </svg> */}
        </div>
    )
}
